import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>
                        Et presentem el millor curs de reforç per preparar l'examen de Dret
                        Administratiu.
                    </strong>
                </p>

                <p>
                    Un any més torna una nova edició i ja s'ha convertit en una formació molt
                    necessària any rere any.
                </p>

                <p>
                    <strong>
                        Un curs de 3 hores en directe a través de ZOOM amb una de les millors
                        docents.
                    </strong>
                </p>

                <p>
                    Durant el curs ens centrarem en els conceptes clau i susceptibles de sortir en
                    examen.
                </p>

                <p>
                    El curs està basat en el temari oficial de la <strong>38a promoció</strong>.
                </p>

                <p>
                    <strong>Dissabte 28 de desembre</strong>
                </p>

                <p>
                    <strong>De 18.00 a 21.00h</strong>
                </p>

                <p>El repàs final per treure bona nota el dia de l'examen oficial.</p>

                <p>
                    El curs és en directe i <strong>quedarà gravat fins al dia de l'examen</strong>.
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="ot el que necessites saber de Dret Administratiu en només 3 hores per triomfar a l'examen de la 38a promoció!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
